import { isBrowser } from "~/lib/is-browser";

type EnvOptions = {
	isSecret?: boolean;
	isRequired?: boolean;
};

function getEnv(
	name: string,
	{ isRequired, isSecret }: EnvOptions = { isSecret: true, isRequired: true }
) {
	if (isBrowser && isSecret) {
		return "";
	}

	const source = (isBrowser ? import.meta.env : process.env) ?? {};

	const value = source[name as keyof typeof source];

	if (!value && isRequired) {
		throw new Error(`${name} is not set`);
	}

	return value;
}

export const INNSIKT_API_KEY = getEnv("INNSIKT_API_KEY");
export const INNSIKT_API_URL = getEnv("INNSIKT_API_URL");

export const SESSION_SECRET = getEnv("SESSION_SECRET");
export const NODE_ENV = getEnv("NODE_ENV", {
	isSecret: false,
	isRequired: false,
});
export const VITE_NODE_ENV = getEnv("VITE_NODE_ENV", {
	isSecret: false,
	isRequired: false,
});
